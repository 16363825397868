import Logo from "../images/greencrosslogo.png";

import { useState } from "react";
import DonationPopup from "../components/DonationPopup";
import Kyc from "../components/Kyc";
import Kyn from "../components/Kyn";
import Profile from "./Landing/profile";

export default function Header() {
  const [headerBar, showHeaderBar] = useState(false);
  const [donationPopup, showDonationPopup] = useState(false);
  const [profile, showProfile] = useState(false);
  const [kyc, showKyc] = useState(false);
  const [kyn, showKyn] = useState(false);

  return (
    <div
      className="z-[999] fixed top-0 right-0 left-0 bg-white/[0.96]"
    >
      {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
      {kyn && <Kyn showKyn={showKyn} />}

      <nav className="container flex items-center justify-between py-2 md:py-4 h-[70px] md:h-[90px]">
        <div className="">
          <a className="logo-wrap inline-flex items-center text-[#097238] text-lg font-bold" href="/">
            <img src={Logo} alt="logo" className="mr-[10px]" />
          </a>
        </div>
        <ul className="links">
          <li>
              <a
                href="/profile"
                className="profile-link"
              >
                Profile
              </a>
          </li>

          <li>
                <a
                  href="/explore"
                  className="profile-link"
                >
                  Explore
                </a>
          </li>
        </ul>
        <ul
          className={`${headerBar === true
            ? "min-h-[180px] opacity-100  py-6 px-5  z-50"
            : ""
            } justify-end items-center btns transition-[all_600ms] block flex md:p-0  bg-white relative text-black text-lg`}
        >
          
          <li className="mr-[10px] md:mb-0">
            <button
              onClick={() => showKyn(true)}
              className="bg-[#18181B] text-white w-[130px] h-12 rounded-[40px] text-[14px] font-medium"
            >
              KYN
            </button>
          </li>

          <li>
            <button
              onClick={() => showDonationPopup(true)}
              className="bg-gradient text-white w-[130px] h-12 rounded-[40px] text-[14px] font-medium"
            >
              DONORS
            </button>
          </li>
        </ul>
        {/* <button
          className="inline-block md:hidden"
          onClick={() => showHeaderBar(!headerBar)}
        >
          <img
            src="/images/bars-solid.svg"
            alt="menu-bars"
            className="w-4 h-4"
          />
        </button> */}
      </nav>
    </div>
  );
}

import Kyn from "../Kyn";
import Header from "../Header";
import Footer from "../Footer";
import Profile from "./profile";
import EventBus from "eventing-bus";
import { useState, useEffect } from "react";
import DonationPopup from "../DonationPopup";
import Submit from "../../images/submit.png";
import Sec4A from "../../images/Sec-4(1).png";
import Sec4B from "../../images/Sec-4(2).png";
import Sec4C from "../../images/Sec-4(3).png";
import Secure from "../../images/greencrosslogo.png";
import Donors from "../../images/donors.png";
import Support from "../../images/support.png";
import Banner from "../../images/Banner-top.png";
import together from "../../images/together.png";
import { HashLink } from 'react-router-hash-link';
import "owl.carousel/dist/assets/owl.carousel.css";
import Fundraisers from "../../images/fundraisers.png";


export default function App() {

  const [donationPopup, showDonationPopup] = useState(false);
  const [kyn, showKyn] = useState(false);
  const [profile, showProfile] = useState(false);
  const [submitKyn] = useState([
    "ID Card Verification",
    "Registration Number",
    "Face Verification",
    "Biometric Verification",
    "Utility Bills as Proof of Address",
  ]);
  const [fundRaising] = useState([
    {
      img: Sec4A,
      head: "Speak Out: ",
      detail: "Tell us who you are and where you’re from. We’re all ears and ready to help you make an actual change in the world.",
    },
    {
      img: Sec4B,
      head: "Fundraising in a Flash: ",
      detail: "You can Set up your fundraiser in a second. Our platform is quick and user-friendly, so your cause gets noticed fast.",
    },
    {
      img: Sec4C,
      head: "Share the Joy:",
      detail: "Tell your friends and family about your cause. You can definitely watch your passion catch on and grow as they pitch in too.",
    },
  ]);
  const [donations] = useState([
    { img: "/images/foster.jpg", name: "Kids in need" },
    { img: "/images/veterans.jpg", name: "Veterans" },
    { img: "/images/birthing.jpg", name: "Birth centers" },
    { img: "/images/communities.jpg", name: "Community in Need" },
    {
      img: "/images/callisthenics.jpg",
      name: "Fitness parks",
    },
    { img: "/images/responders.jpg", name: "Emergency Teams" },
  ]);

  return (
    <div className="overflow-hidden">
      <Header />
      {donationPopup && <DonationPopup showDonationPopup={showDonationPopup} />}
      {kyn && <Kyn showKyn={showKyn} />}
      {profile && <Profile showProfile={showProfile} />}
      <div className="relative sec-1 ">
        <img
          src={Banner}
          className="desk-img md:absolute hidden md:inline-block right-0 top-0 lg:top-[50%] ml-auto bottom-0 max-h-screen ty-50 w-full md:w-auto object-cover fil-gr -z-50 mt-[70px] md:mt-[140px] lg:mt-[50px] max-w-[650px]"
          alt="banner"
        />
        <img src={Banner} alt="banner" className="block md:hidden pt-[70px] fil-gr w-full object-cover" />
        <div className="container py-10 md:py-0 md:h-screen">
          <div
            className="md:max-w-[580px] h-full flex flex-col justify-center text-center md:text-left"
          >
            <h4 className="text-[#18181B] font-normal text-[36px] md:text-[48px] lg:text-[60px] leading-[1.3em] -mb-3">
              Green Cross: Your Donations Can Make a Difference
            </h4>
            <p className=" lg:text-[16px] leading-[1.5em] text-[#18181B] font-normal mt-6 mb-8 md:max-w-[385px] lg:max-w-full ">
              Step into Green Cross, where every dollar blossoms into a beacon of hope. We’re redefining aid with innovative paths to generosity. Our platform ensures your goodwill travels the distance, touching lives across the globe. With our platform, you’re not just spending—you’re sparking joy, illuminating dark corners with the light of kindness. We can make difference together.
            </p>
            <div className="flex flex-wrap items-center gap-[10px] justify-center md:justify-start">
              <button
                onClick={() => showKyn(true)}
                className="bg-[#18181B] text-white w-[47%] md:w-[180px] h-[62px] rounded-[40px] text-[13px] md:text-[15px] font-medium"
              >
                FUNDRAISERS
                <span className="block text-[#28B467] text-[10px] md:text-xs font-medium">
                  SUBMIT KYN
                </span>
              </button>
              <button
                onClick={() => showDonationPopup(true)}
                className="bg-gradient text-white w-[47%] md:w-[180px] h-[62px] rounded-[40px] text-[13px] md:text-[15px] font-medium"
              >
                DONATE TODAY
                <span className="block text-[#18181B] text-[10px] md:text-xs font-medium">
                  START DONATION
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="relative sec-2 sec">
        <div className="container pt-4 md:pt-16">
          <div className="inner">
            <div className="img-wrap">
              <img
                src={together}
                className="fil-gr rounded-[10px]"
                alt="banner"
              />
            </div>
            <div
              className="content max-w-[576px] h-full flex flex-col items-center md:items-start ml-auto md:pt-10 text-center md:text-left justify-center"
            >
              <h4 className="text-[#18181B] text-[36px] md:text-[40px] font-normal leading-[1.3em] -mb-3">
                Give with Heart, Change the World
              </h4>
              <p className="text-[14px] leading-[1.5em] text-[#18181B] font-normal mt-6 mb-8 md:max-w-[576px] lg:max-w-full">
                At Green Cross, we are deeply moved by the boundless potential of united kindness. Together, we forge a legacy of global betterment that touches hearts and changes lives. We empower you to champion the causes that ignite your passion. Your gift is more than mere aid; it’s the beacon of hope for enduring progress.
              </p>
              <HashLink
                className=""
                smooth to='/#causes'
              >
                <button
                  className="bg-[#18181B] text-white w-[202px] h-[49px] rounded-[40px] text-lg font-medium mt-6 md:mt-0"
                >
                  Discover Causes
                </button>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-14 md:mt-[100px] sec-3 sec">
        <div className="container mt-14 md:mt-0 md:py-[100px] lg:py-[154px]">
          <div className="inner">
            <div
              className="content md:max-w-[576px] text-center md:text-left h-full flex flex-col justify-center"
            >
              <h4 className="text-[#18181B] font-normal text-[36px] md:text-[40px] leading-[1.3em] -mb-3">
                A Brighter Future Starts with You
              </h4>
              <p className="text-[14px] leading-[1.5em] text-[#18181B] font-normal mt-6 mb-8 md:max-w-[576px] lg:max-w-full">
                At Green Cross, we envision a tomorrow that glows with the warmth of hope and the promise of opportunity. When you donate through us, you can feel a profound sense of assurance that your funds are channeled directly to the causes that stir your soul. Your contribution becomes a lighthouse of positive change, casting a radiant light that illuminates the path toward a brighter future for all of us.
              </p>
            </div>

            <div className="img-wrap">
              <img
                src={together}
                className="fil-gr rounded-[10px]"
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden sec-4">
        <div className="container">
          <div className="pt-[106px] md:pt-[55px] pb-[60px] md:pb-[100px]">
            <div className="max-w-[650px] mx-auto pattern">
              <h4 data-aos="fade" data-aos-duration="1500" className="text-center text-[#18181B] font-normal text-[36px] md:text-[40px] lg:text-[56px] leading-[1.3em] mb-5">
                Take the First Step: It’s Easy
              </h4>
              <p className="text-[16px] text-[#18181B] text-center">
                How to begin?
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-12 gap-5 md:gap-8  py-[12px]">
              {fundRaising.map((value, index) => (
                <div className={`box`} key={index}>
                  <img src={value.img} alt="first" className="mx-auto" />
                  <h4 className="text-[24px] text-center font-normal text-[#18181B] flex justify-center items-center leading-[1.3em] mt-6 mb-4">
                    {value.head}
                  </h4>
                  <p className="text-[#18181B] text-center text-[16px] font-normal md:leading-[1.5em]">
                    {value.detail}
                  </p>
                </div>
              ))}
            </div>
            <button
              onClick={() => showKyn(true)}
              className="bg-[#18181B] text-white w-[240px] h-[62px] rounded-[40px] text-lg font-medium mx-auto block mt-20"
            >
              Start Raising Funds
              <span className="block text-[#28B467] text-xs font-medium mt-1">
                SUBMIT KYN
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="sec-5">
        <div className="py-[60px] md:py-[90px] relative overflow-hidden">

          <div id="causes" className="container overflow-hidden">
            <div className="text-center md:text-left">
              <img src={Secure} alt="secure" className="w-[150px] mb-12 mx-auto md:mx-0 " />
              <div className="">
                <h4 className="text-[24px] font-normal text-[#18181B] mb-4">
                  Why Should You Choose Us?
                </h4>
                <h4 className="text-[#097238] font-normal text-[36px] md:text-[40px] lg:text-[56px] leading-[1.3em] mb-4">
                  Wondering why to choose us for your giving? We’re not just about donations; we turn your crypto into real help for communities. Here’s how you can make a big splash:
                </h4>
                <h4 className="text-[16px] font-normal text-[#18181B]">
                  We bring real Impact through our platform. Every penny counts. Your donation can help the following:
                </h4>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] mt-20">
                {donations.map((value, index) => (
                  <div
                    className="w-full overflow-hidden relative box"
                    key={index}
                  >
                    <div className="img-wrap">
                      <img
                        src={value.img}
                        alt={value.name}
                        className="h-full w-full object-cover rounded-[8px] mb-6"
                      />
                    </div>

                    <span className="text-[21px] font-medium text-[#18181B] text-center block">
                      {value.name}
                    </span>
                  </div>
                ))}
              </div>
              <p className="text-[16px] font-normal text-[#18181B] mt-20 mb-12">
                These causes are pressing issues that we see every day, and it's
                crucial that we work together to address them. With your
                support, we can make a difference and turn these causes into
                successful stories of social change.
              </p>
              <button
                onClick={() => showDonationPopup(true)}
                className="bg-gradient w-[200px] h-[64px] rounded-[50px] text-[18px] font-medium mx-auto block mt-5 text-white"
              >
                Start DONATION
                <span className="block text-xs font-medium text-[#18181B]">
                  DONATE TODAY
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative md:mt-[100px] sec sec-6">
        <div className="container py-10">
          <div className="inner">
            <div
              className="content md:max-w-[480px] lg:max-w-[580px] h-full flex flex-col justify-center text-center md:text-left"
            >
              <h4 className="text-[24px] font-normal text-[#18181B] mb-4">
                Start a Wave of Change
              </h4>
              <h4 className="text-[#18181B] font-normal text-[36px] md:text-[40px] lg:text-[56px] leading-[1.3em] mb-4">
                Why Green Cross Stands Out:
              </h4>
              <p className="text-[16px] leading-[1.5em] text-[#18181B] font-normal">
                With <span className="text-[#28B467]">Green Cross Donations</span>, you can
                easily create a fundraising campaign for{" "}
                <span className="text-[#28B467]">
                  yourself, your friends and family, or a charitable organization.{" "}
                </span>
                <br />
                <br />
                Every donation, no matter how big or small, helps bring about
                change and makes a positive impact. Be a source of change and
                start your fundraising journey with us today.
              </p>
            </div>

            <div className="img-wrap">
              <img
                src={Support}
                className="fil-gr rounded-[10px]"
                alt="banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative py-8 md:py-[120px] sec-7">
        <div className="container">
          <div className="max-w-[600px] mx-auto  text-center ">
            <h4 className="text-[36px] md:text-[48px] lg:text-[60px] font-normal text-[#18181B] mb-4">
              How does it work?
            </h4>
            <p className="text-[#18181B] text-[16px] font-normal leading-[1.5em] md:mt-3 max-w-[320px] mx-auto">
              At Green Cross, we revolutionize the donation experience by integrating crypto wallets. We are the only pltform to create a harmonious bridge between donors and fundraisers:
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-10 md:mt-20">
            <div className="text-center box">
              <img src={Donors} alt="donors" className="mx-auto max-h-[260px] md:max-h-full" />
              <h4 className="text-[#18181B] text-[24px] font-normal leading-[1.3em] mt-8 mb-4">
                Connect & Contribute:
              </h4>
              <p className="text-[#18181B] text-[16px] font-normal leading-[1.8em] md:max-w-[504px] mx-auto">
                Once connected, donors can effortlessly donate by clicking the “Start Donation” button. Select your cause, follow the intuitive process, and ignite change with a few simple clicks.
              </p>
              <button
                onClick={() => showDonationPopup(true)}
                className="mt-10 w-[150px] text-[16px] text-white font-medium bg-gradient h-[50px] rounded-[40px]"
              >
                Start Donation
              </button>
            </div>

            <div className="text-center box">
              <img src={Fundraisers} alt="donors" className="mx-auto max-h-[260px] md:max-h-full" />
              <h4 className="text-[#18181B] text-[24px] font-normal leading-[1.3em] mt-8 mb-4">
                Fundraisers: Empower Your Mission
              </h4>
              <p className="text-[#18181B] text-[16px] font-normal leading-[1.8em] md:max-w-[504px] mx-auto">
                Fundraisers eager to spark transformation can submit KYN for access to Green Cross’ advanced features. This includes adding/updating donation details and streamlining transactions through integrated crypto wallets, enhancing your ability to make a real difference.
              </p>
              <button
                onClick={() => showKyn(true)}
                className="mt-10 w-[150px] text-[16px] text-white font-medium bg-[#18181B] h-[50px] rounded-[40px]"
              >
                Submit KYN
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-[60px] md:pb-[90px]">
        <div className="p-5 md:p-[60px] bg-[#1a1c29] rounded-[23px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[60px]">
            <div className="flex justify-center flex-col items-center md:items-start md:justify-start text-center md:text-left mb-8 md:mb-0">
              <img src={Submit} alt="kynSubmit" />
              <h4 className="text-[32px] md:text-[39px] lg:text-[48px] font-normal text-white -tracking-[1.5px] leading-[1.2em] mt-7 mb-3">
                How to submit KYN?
              </h4>
              <p className="text-[16px] leading-[1.8em] font-normal text-white">
                KYN (Know Your Non-Profit) is a vital process necessary for non-profit organizations to ensure integrity and become eligible to receive funds for their noble cause. To initiate the KYN submission, non-profit organizations simply need to provide the required information to our super admin for meticulous verification, fortifying the trust in every transaction.
              </p>
            </div>
            <div>
              <ul className="flex flex-col gap-[24px]">
                {submitKyn.map((value, index) => (
                  <li className="h-[50px] rounded-[40px] bg-gradient flex items-center px-3 md:px-5" key={index}>
                    <span className="h-[30px] mr-2 md:mr-[10px] rounded-full bg-[#18181B] min-w-[30px] inline-flex items-center justify-center text-[15px] md:text-[17px] font-semibold text-white">
                      {index + 1}
                    </span>
                    <p className="text-[16px] font-medium text-white grow text-center">
                      {value}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
